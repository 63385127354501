<template>
	<div>
		<base-data-table
			v-bind="currentTableAttrs"
			:title="$t('contract')"
			:tabSliders="tabSliders"
			:tab-slider.sync="tabSlider"
			:add="!$currentUser.isBuyer"
			translate
			@view="onViewContract"
			@status="onChangeStatus"
			@update="onUpdateContract"
			@click:add="onInitContract"
		>
			<template v-slot:[`item.serial`]="{ index }">
				{{
					currentTableAttrs.provider.perPage *
						(currentTableAttrs.provider.currentPage - 1) +
						index +
						1
				}}
			</template>

			<template v-slot:[`item.phone`]="{ item }">
				<base-phone-number
					:phone="$get(item, 'contractable.company.phone')"
					class="tw-whitespace-nowrap tw-flex tw-items-center tw-space-s-3"
				>
					<template #default="{ phone, country }">
						<v-avatar v-if="country" size="20">
							<base-flag height="20" width="20" :code="country" />
						</v-avatar>
						<span>{{ phone }}</span>
					</template>
				</base-phone-number>
			</template>

			<template v-slot:[`item.status`]="{ item }">
				<div>
					<div
						:class="item.status ? 'tw-text-AA' : 'tw-text-72'"
						class="tw-capitalize tw-text-sm tw-font-semibold"
					>
						{{ item.status ? 'active' : 'inactive' }}
						<!-- {{ item.status }} -->
					</div>
				</div>
			</template>
		</base-data-table>

		<v-dialog
			persistent
			max-width="1198"
			v-model="contractForm.dialog"
			content-class="tw-rounded-2xl tw-shadow-lg"
		>
			<ContractForm
				v-if="contractForm.dialog"
				:contract="contractForm.data"
				:is-view.sync="contractForm.view"
				:error="contractForm.errors"
				:loading="contractForm.loading"
				:contractableType="tabSlider"
				@update="handleUpdateContract"
				@create="handleCreateContract"
				@onClose="onResetContractForm"
			/>
		</v-dialog>
	</div>
</template>

<script>
import { ROLE } from '../../consts'
import { toFormData } from 'vuelpers'
import { mapActions, mapGetters } from 'vuex'

import ContractForm from '../../components/forms/ContractForm.vue'

export default {
	name: 'Contract',
	components: {
		ContractForm,
	},
	data: () => ({
		tabSlider: 'buyer',
		contractForm: {
			data: { id: null },
			dialog: false,
			view: false,
			errors: {},
			loading: false,
		},
	}),
	created() {
		const { isBuyer, isSupplier } = this.$currentUser
		this.tabSlider = isSupplier
			? 'supplier'
			: isBuyer
			? 'buyer'
			: this.tabSlider

		if (!this.$buyers.data.length) {
			this.getBuyers()
		}
		if (!this.$suppliers.data.length) {
			this.getSuppliers({
				select: 'id',
				includes: 'company|name',
			})
		}
	},
	computed: {
		...mapGetters('auth', ['$currentUser']),
		...mapGetters('contracts', [
			'$buyerContracts',
			'$supplierContracts',
			'$suppliers',
			'$buyers',
		]),
		actions() {
			return [
				{
					text: this.$t('view-details'),
					event: 'view',
					icon: 'mdi-eye-outline',
				},
				this.$currentUser.isAdmin && {
					text: this.$t('update-details'),
					event: 'update',
					icon: 'mdi-pencil',
				},
				this.$currentUser.isAdmin && {
					text: v => {
						return v.status
							? this.$t('cancel_the_contract')
							: this.$t('actions.activate')
					},
					event: 'status',
					icon: 'mdi-checkbox-intermediate',
				},
			].filter(Boolean)
		},
		currentTableAttrs() {
			return {
				buyer: {
					name: this.$t('offers'),
					actions: this.actions,
					headers: this.headers,
					provider: this.$buyerContracts,
					fetchFunction: this.fetchBuyerContact,
				},
				supplier: {
					name: this.$t('suppliers'),
					actions: this.actions,
					headers: this.headers,
					provider: this.$supplierContracts,

					fetchFunction: this.fetchSupplierContact,
				},
			}[this.tabSlider]
		},
		tabs() {
			if (!this.$currentUser.isAdmin) return []
			return [{ text: this.$t('contract-list'), value: 'offers' }]
		},
		tabSliders() {
			if (!this.$currentUser.isAdmin) return []
			return [
				{ text: "Buyer's", value: 'buyer' },
				{ text: "Supplier's", value: 'supplier' },
			]
		},
		headers() {
			return [
				{ text: 'headers.ns', value: 'serial' },
				{ text: 'headers.registrationNumber', value: 'registrationNumber' },
				{ text: 'headers.contractNumber', value: 'contractNumber' },
				{ text: 'headers.company', value: 'contractable.company.name' },
				{ text: 'headers.phone', value: 'phone' },
				{ text: 'headers.email', value: 'contractable.company.email' },
				{ text: 'headers.startingDate', value: 'startingDate' },
				{
					text: 'headers.status',
					value: 'status',
					// computed: (item) => {
					// 	return item.status
					// 		? this.$t('status.active')
					// 		: this.$t('status.deactive')
					// },
				},
				{ text: 'headers.actions', value: 'actions' },
			]
		},
	},

	methods: {
		...mapActions('contracts', [
			'getContracts',
			'createContract',
			'updateContract',
			'getBuyers',
			'getSuppliers',
		]),
		onChangeStatus(e) {
			const isSupplier = e.contractableType.includes(this.$t('supplierText'))
			this.handleUpdateContract(
				{
					id: e.id,
					contractableId: e.contractableId,
					contractableType: isSupplier ? ROLE.SUPPLIER : ROLE.BUYER,
					status: !e.status,
				},
				{
					title: this.$t('contract-activate-cancel'),
					message: this.$t('sure-to-cancel-activate-contract', [
						e.status ? 'cancel' : 'activate',
					]),
				}
			)
		},
		handleCreateContract(data) {
			this.$root.confirmation = {
				title: this.$t('create-contract-confirmation'),
				message: this.$t('sure-create-contract'),
				handler: async () => {
					let [err] = await this.createContract(
						toFormData(data, {}, { convertCase: 'snake_case' })
					)
					if (err) {
						return this.$toast.error(
							err.message ||
								this.$t('error-creating', [this.$t('contract')])
						)
					}
					this.$toast.success(
						this.$t('success-create', [this.$t('contract')])
					)
					this.onResetContractForm()
				},
			}
		},
		handleUpdateContract(data, config = {}) {
			this.$root.confirmation = {
				title: this.$t('update-confirmation'),
				message: this.$t('sure-to-update-contract'),
				...config,
				handler: async () => {
					let [err] = await this.updateContract(
						toFormData(data, {
							// _method: 'PATCH',
							convertCase: 'snake_case',
						})
					)
					if (err) {
						return this.$toast.error(
							err.message ||
								this.$t('error-updating', [this.$t('contract')])
						)
					}
					this.$toast.success(
						this.$t('success-update', [this.$t('contract')])
					)
					this.onResetContractForm()
				},
			}
		},
		fetchBuyerContact(queries = {}) {
			return this.getContracts({
				contractableType: 'buyer',
				...queries,
			})
		},
		fetchSupplierContact(queries = {}) {
			return this.getContracts({
				contractableType: 'supplier',
				...queries,
			})
		},
		onResetContractForm() {
			this.contractForm = {
				view: false,
				dialog: false,
				loading: false,
				errors: {},
				data: { id: null },
			}
		},
		onInitContract() {
			this.onResetContractForm()
			this.contractForm.view = false
			this.contractForm.dialog = true
		},
		onUpdateContract(item) {
			this.contractForm.data = item
			this.contractForm.view = false
			this.contractForm.dialog = true
		},
		onViewContract(item) {
			this.contractForm.data = item
			this.contractForm.view = true
			this.contractForm.dialog = true
		},
	},
}
</script>
