<template>
	<v-form
		:readonly="isView"
		ref="formRef"
		lazy-validation
		@submit.prevent="onSubmitContract"
	>
		<v-card
			class="tw-overflow-x-hidden tw-overflow-y-auto tw-px-4 lg:tw-px-14 lg:tw-py-9 tw-py-6"
		>
			<div class="tw-flex tw-justify-between tw-items-center">
				<h2 class="tw-text-22 tw-font-semibold tw-text-black">
					{{
						isView
							? $t('viewContract')
							: isUpdate
							? $t('updateContract')
							: $t('addNewContract')
					}}
				</h2>
				<v-btn
					dark
					fab
					depressed
					width="50"
					height="50"
					color="#DF9999"
					class="tw-rounded-md"
					@click="onResetForm"
				>
					<v-icon color="tw-text-white">mdi mdi-close</v-icon>
				</v-btn>
			</div>

			<v-card-text>
				<v-row>
					<v-radio-group
						v-if="$currentUser.isAdmin"
						v-model="mContract.contractableType"
						row
						class="tw-w-full"
						@change="mContract.contractableId = null"
					>
						<v-col cols="12" md="6">
							<div
								class="tw-w-full tw-border-f0f tw-border tw-rounded-sm tw-h-14 tw-flex tw-items-center"
							>
								<v-radio
									:label="$t('buyerText')"
									class="tw-mx-4"
									color="#f2ca51"
									value="buyer"
								></v-radio>
							</div>
						</v-col>
						<v-col cols="12" md="6">
							<div
								class="tw-w-full tw-border-f0f tw-border tw-rounded-sm tw-h-14 tw-flex tw-items-center"
							>
								<v-radio
									class="tw-mx-4"
									:label="$t('supplierText')"
									color="#f2ca51"
									value="supplier"
								></v-radio>
							</div>
						</v-col>
					</v-radio-group>

					<v-col v-if="$currentUser.isAdmin" cols="12" md="6">
						<div v-if="mContract.contractableType == 'supplier'">
							<div
								class="tw-mb-4 tw-font-medium tw-flex tw-items-center tw-text-47"
							>
								{{ $t('selectSupplier') }}
							</div>
							<v-autocomplete
								v-model="mContract.contractableId"
								dense
								flat
								outlined
								clearable
								:items="suppliers"
								item-value="value"
								item-text="label"
								class="user-input"
								hide-details="auto"
								:placeholder="$t('selectSupplier')"
								:rules="[rules.required($t('supplierText'))]"
							/>
						</div>
						<div v-if="mContract.contractableType == 'buyer'">
							<div
								class="tw-mb-4 tw-font-medium tw-flex tw-items-center tw-text-47"
							>
								{{ $t('select-buyer') }}
							</div>
							<v-autocomplete
								v-model="mContract.contractableId"
								dense
								flat
								outlined
								clearable
								item-value="value"
								item-text="label"
								class="user-input"
								hide-details="auto"
								:items="buyers"
								:placeholder="$t('selectBuyer')"
								:rules="[rules.required($t('buyerText'))]"
							/>
						</div>
					</v-col>
					<v-col cols="12" md="6">
						<div
							class="tw-mb-4 tw-font-medium tw-flex tw-items-center tw-text-47"
						>
							{{ $t('contractNumber') }}
						</div>
						<v-text-field
							outlined
							flat
							:rules="[rules.required($t('contractNumber'))]"
							class="user-input"
							hide-details="auto"
							:placeholder="$t('contractNumber')"
							v-model="mContract.contractNumber"
							dense
							:height="$vuetify.breakpoint.lgAndUp ? 60 : ''"
							:error-messages="
								error.contractNumber ? error.contractNumber[0] : []
							"
						/>
					</v-col>
					<v-col cols="12" md="6">
						<div
							class="tw-mb-4 tw-font-medium tw-flex tw-items-center tw-text-47"
						>
							{{ $t('registrationNumber') }}
						</div>
						<v-text-field
							outlined
							flat
							:rules="[rules.required($t('registrationNumber'))]"
							class="user-input"
							hide-details="auto"
							v-model="mContract.registrationNumber"
							:placeholder="$t('registrationNumber')"
							dense
							:height="$vuetify.breakpoint.lgAndUp ? 60 : ''"
							:error-messages="
								error.registrationNumber
									? error.registrationNumber[0]
									: []
							"
						/>
					</v-col>
					<v-col cols="12" md="6">
						<BaseDatePicker
							v-model="mContract.startingDate"
							:label="$t('startDate')"
							:rules="[rules.required($t('startDate'))]"
							:height="$vuetify.breakpoint.lgAndUp ? '60px' : ''"
							input-class="user-input fieldset-f0"
							:readonly="isView"
						/>
					</v-col>

					<v-col cols="12">
						<div
							v-if="isView"
							class="tw-font-medium tw-flex tw-items-center tw-text-47"
						>
							{{ $t('attachments') }}
						</div>
						<BaseUpload
							v-model="mContract.attachments"
							:readonly="isView"
							class="tw-mt-2.5"
						/>
						<span
							v-if="showAttachError"
							class="red--text tw-ms-2 tw-text-xs"
							>{{ $t('headers.attachment') }}
							{{ $t('isRequired') }}</span
						>
					</v-col>
				</v-row>
			</v-card-text>
			<v-card-actions
				v-if="!isView && (isCreate || isUpdate)"
				class="tw-mt-10 lg:tw-mt-14 tw-flex tw-p-0"
			>
				<v-btn
					outlined
					color="primary"
					height="58"
					class="tw-flex-1 tw-rounded-lg"
					text
					@click="onResetForm"
				>
					<span class="tw-text-lg tw-font-medium tw-capitalize tw-text-72"
						>{{ $t('actions.cancel') }}
					</span>
				</v-btn>
				<v-btn
					:loading="loading"
					depressed
					height="58"
					type="submit"
					color="primary"
					class="tw-flex-1 tw-font-medium tw-rounded-lg"
				>
					<span
						class="tw-text-lg tw-font-medium tw-capitalize tw-text-white"
					>
						{{ $t('actions.submit') }}
					</span>
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-form>
</template>

<script>
import { mapGetters } from 'vuex'
import { ROLE } from '../../consts'
import { omitEmpties } from 'vuelpers'
import { createFormMixin } from '@/mixins/form-mixin'
import { getFile, toAttachment } from '../../helpers'

export default {
	name: 'FormContract',
	mixins: [
		createFormMixin({
			rules: ['required'],
		}),
	],
	props: {
		error: Object,
		contract: Object,
		isView: {
			type: Boolean,
			default: false,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		contractableType: {
			type: String,
			default: 'buyer',
		},
	},
	watch: {
		contract: {
			deep: true,
			immediate: true,
			handler(v) {
				this.mContract = this.initialContract(v)
			},
		},
		contractableType: {
			immediate: true,
			handler(v) {
				this.mContract.contractableType = v
			},
		},
	},
	data() {
		return {
			mContract: {},
			showAttachError: false,
		}
	},
	computed: {
		...mapGetters('auth', ['$currentUser']),
		...mapGetters('contracts', ['$suppliers', '$buyers']),
		isUpdate() {
			return !!this.mContract.id
		},
		isCreate() {
			return !this.isUpdate && !this.isView
		},
		suppliers() {
			return this.$suppliers.data.map(supplier => ({
				value: supplier.id,
				label: supplier.company.name,
			}))
		},
		buyers() {
			return this.$buyers.data.map(buyer => ({
				value: buyer.id,
				label: buyer.company.name,
			}))
		},
	},
	methods: {
		onResetForm() {
			this.$refs.formRef.reset()
			this.$emit('onClose')
		},
		initialContract(v) {
			const isSupplier =
				this.$currentUser.isSupplier ??
				v?.contractableType?.includes(this.$t('supplierText'))
			return {
				id: v?.id,
				contractableId: this.$currentUser.isBuyer
					? this.$currentUser.buyer[0].id
					: this.$currentUser.isSupplier
					? this.$currentUser.supplier[0].id
					: v?.contractableId,
				contractableType: isSupplier ? ROLE.SUPPLIER : ROLE.BUYER,
				startingDate: v?.startingDate,
				contractNumber: v?.contractNumber,
				registrationNumber: v?.registrationNumber,
				attachments: toAttachment(v?.attachments),
			}
		},
		onSubmitContract() {
			this.showAttachError = !this.mContract.attachments

			if (!this.$refs.formRef.validate() || this.showAttachError) {
				this.$utils.scrollIntoError(this)
				return
			}
			let contract = omitEmpties({
				...this.mContract,
				attachments: getFile(this.mContract?.attachments?.file),
			})

			this.isUpdate
				? this.$emit('update', contract)
				: this.$emit('create', contract)
		},
	},
}
</script>

<style>
.user-input.v-text-field--outlined fieldset {
	color: #f0f0f0 !important;
}
.user-input input::placeholder {
	color: black !important;
	opacity: 0.25;
	font-size: 16px !important;
}
</style>
