var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('base-data-table',_vm._b({attrs:{"title":_vm.$t('contract'),"tabSliders":_vm.tabSliders,"tab-slider":_vm.tabSlider,"add":!_vm.$currentUser.isBuyer,"translate":""},on:{"update:tabSlider":function($event){_vm.tabSlider=$event},"update:tab-slider":function($event){_vm.tabSlider=$event},"view":_vm.onViewContract,"status":_vm.onChangeStatus,"update":_vm.onUpdateContract,"click:add":_vm.onInitContract},scopedSlots:_vm._u([{key:"item.serial",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s(_vm.currentTableAttrs.provider.perPage * (_vm.currentTableAttrs.provider.currentPage - 1) + index + 1)+" ")]}},{key:"item.phone",fn:function(ref){
var item = ref.item;
return [_c('base-phone-number',{staticClass:"tw-whitespace-nowrap tw-flex tw-items-center tw-space-s-3",attrs:{"phone":_vm.$get(item, 'contractable.company.phone')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var phone = ref.phone;
var country = ref.country;
return [(country)?_c('v-avatar',{attrs:{"size":"20"}},[_c('base-flag',{attrs:{"height":"20","width":"20","code":country}})],1):_vm._e(),_c('span',[_vm._v(_vm._s(phone))])]}}],null,true)})]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('div',{staticClass:"tw-capitalize tw-text-sm tw-font-semibold",class:item.status ? 'tw-text-AA' : 'tw-text-72'},[_vm._v(" "+_vm._s(item.status ? 'active' : 'inactive')+" ")])])]}}],null,true)},'base-data-table',_vm.currentTableAttrs,false)),_c('v-dialog',{attrs:{"persistent":"","max-width":"1198","content-class":"tw-rounded-2xl tw-shadow-lg"},model:{value:(_vm.contractForm.dialog),callback:function ($$v) {_vm.$set(_vm.contractForm, "dialog", $$v)},expression:"contractForm.dialog"}},[(_vm.contractForm.dialog)?_c('ContractForm',{attrs:{"contract":_vm.contractForm.data,"is-view":_vm.contractForm.view,"error":_vm.contractForm.errors,"loading":_vm.contractForm.loading,"contractableType":_vm.tabSlider},on:{"update:isView":function($event){return _vm.$set(_vm.contractForm, "view", $event)},"update:is-view":function($event){return _vm.$set(_vm.contractForm, "view", $event)},"update":_vm.handleUpdateContract,"create":_vm.handleCreateContract,"onClose":_vm.onResetContractForm}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }